import React, { useLayoutEffect } from 'react';
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from '../utils/contexts/tracker-context';
import Splash from '../components/UI/Splash';
import LanguageSelector from '../components/LanguageSelector';
import features from '../utils/features';
import PoweredBy from '../components/UI/PoweredBy';

function SplashPage(props) {
    const { trackDataFn } = useTracker();

    useLayoutEffect(() => {
        async function track() {
            await trackDataFn(4, "ff5x6qcw1", "usersession", {}, true);
        }

        track();
    }, []);

    return (
        <IonPage>
            <IonContent style={{ "--background": "#F6BE01" }}>
                <div className="flext justify-around h-screen relative">
                    <section id="splash-page" className="font-sans">
                        <Splash />
                    </section>

                    <div className="absolute bottom-0 mx-auto w-full">
                        <PoweredBy />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default SplashPage;
