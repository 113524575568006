import React from 'react';
import Product from './Product';
import formats from '../../utils/formats';
import clsx from 'clsx';

function Products(props) {
    let { course, products, index, isTastingMenu } = props;

    let classnames = [
        "products",
        { "even bg-white": formats.isEven(index) },
        { "odd bg-[#F2EFEF]": !formats.isEven(index) }
    ];

    return (
        <div className={clsx(classnames)}>
            {0 === products.length ?
                (<p className="text-center pb-1">Nessun prodotto rispetta le tue preferenze.</p>) :
                (products.map((product, i) => <Product course={course} index={index} product={product} key={i} isTastingMenu={isTastingMenu} />))}
        </div>
    );
}

export default Products;
