import React, { useEffect, useState } from 'react';
import client from '../../logic/courses';
import { IonSpinner } from '@ionic/react';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Products from './Products';
import dl from '../../utils/device-language';
import { useTranslation } from 'react-i18next';
import { useFilters } from '../../utils/contexts/filters-context';
import { useParams } from 'react-router';

function TastingMenu(props) {
    const { course, index, tastingMenu } = props;

    const [products, setProducts] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const { filters } = useFilters();
    const params = useParams();

    const { i18n } = useTranslation();

    async function onOpening() {
        // populate products with course products
        // products are loaded from courses logic

        let clientCourse;

        let language = process.env.REACT_APP_LANGUAGESELECTOR === "true" ? i18n.language : dl.getApiLanguage();


        clientCourse = await client.doGetTastingMenu(language, course.id, tastingMenu.id);

        setProducts(clientCourse.dishes);
        setLoading(false);
    }

    useEffect(() => {
        if (open == true) {
            setOpen(prevState => !prevState);
        }
    }, [filters])

    return (
        <Collapsible
            className="row"
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={course} setOpen={setOpen}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={course} opened setOpen={setOpen}></CollapsibleTrigger>}
            onTriggerOpening={onOpening}
            open={open}>
            <div>
                {tastingMenu && params.id == "ffnjDuqPE" && <img className="object-cover w-full h-60" src={`assets/monticello/${course.id}.jpg`} alt="portata" />}
                {isLoading ?
                    <IonSpinner name="dots" /> :
                    <Products products={products} index={index} isTastingMenu={tastingMenu != undefined || tastingMenu != null} />
                }
            </div>
        </Collapsible>
    );
}

export default TastingMenu;
