import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { arrowForward } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import axios from 'axios';

function Splash(props) {
    const history = useHistory();
    const [bistrotIsVisible, setBistrotIsVisible] = useState(false);
    const [poolBarIsVisible, setPoolBarIsVisible] = useState(false);
    const [chiringuitoIsVisible, setChiringuitoIsVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    async function handler(path) {
        if (path == "bistrot") {
            history.push("/t/q/ffnjDuqPE/menu");
        } else if (path == "poolbar") {
            history.push("/t/q/C6jauF3Sn/menu");
        } else if (path == "chiringuito") {
            history.push("/t/q/pWcA3Ii1e/menu");
        }
    }

    useEffect(() => {
        async function getActives() {
            const ENDPOINT_DASHBOARD = "https://orders.mycontactlessmenu.mycia.it/api/businesses/";
            const bistrotIsVisibleRes = await axios.get(ENDPOINT_DASHBOARD + "ffnjDuqPE");
            const poolBarIsVisibleRes = await axios.get(ENDPOINT_DASHBOARD + "C6jauF3Sn");
            const chiringuitoIsVisibleRes = await axios.get(ENDPOINT_DASHBOARD + "pWcA3Ii1e");

            setBistrotIsVisible(bistrotIsVisibleRes.data.is_visible);
            setPoolBarIsVisible(poolBarIsVisibleRes.data.is_visible);
            setChiringuitoIsVisible(chiringuitoIsVisibleRes.data.is_visible);
        }

        getActives().then(_ => setLoading(false));
    }, []);

    useEffect(() => {
        const now = new Date();
        if (bistrotIsVisible === true && now.getHours() >= 14 && now.getMinutes() >= 30) {
            setBistrotIsVisible(false);
        }
    }, [bistrotIsVisible]);

    return (
        <div className="splash pb-16">
            <div className="bg-white">
                {/* <img src="/assets/monticello/logo1.png" className="w-1/2 mx-auto pt-6" alt="logo" /> */}
                <img src={"/assets/monticello/logo2.jpg"} alt={t("splash.img_1")} />
            </div>

            <div className="relative py-6 px-4 text-center">
                {!loading ?
                    <Fragment>
                        {bistrotIsVisible ?
                            <button type="button" onClick={() => handler("bistrot")} className="py-3 px-8 my-8 w-full flex items-center justify-between cursor-pointer rounded-full bg-white shadow-xl">
                                <p>Bistrot</p>
                                <div className="flex w-10 h-10 items-center justify-center rounded-full bg-[#FEDB01]">
                                    <IonIcon className="w-6 h-6 text-black" icon={arrowForward} />
                                </div>
                            </button> :
                            null}

                        {poolBarIsVisible ?
                            <button type="button" onClick={() => handler("poolbar")} className="py-3 px-8 my-8 w-full flex items-center justify-between cursor-pointer rounded-full bg-white shadow-xl">
                                <p>PoolBar</p>
                                <div className="flex w-10 h-10 items-center justify-center rounded-full bg-[#FEDB01]">
                                    <IonIcon className="w-6 h-6 text-black" icon={arrowForward} />
                                </div>
                            </button> :
                            null}

                        {chiringuitoIsVisible ?
                            <button type="button" onClick={() => handler("chiringuito")} className="py-3 px-8 my-8 w-full flex items-center justify-between cursor-pointer rounded-full bg-white shadow-xl">
                                <p>Chiringuito</p>
                                <div className="flex w-10 h-10 items-center justify-center rounded-full bg-[#022169]">
                                    <IonIcon className="w-6 h-6 text-white" icon={arrowForward} />
                                </div>
                            </button> :
                            null}
                    </Fragment> :
                    <p className="pt-10 text-black text-xl">Caricamento...</p>}
            </div>
        </div>
    );
}

export default Splash;
