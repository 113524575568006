import React, { useState } from 'react';
import { useParams } from 'react-router';
import Cta from '../cart/Cta';
import Details from '../dish/Details';
import PreferredButton from '../UI/PreferredButton';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import assets from '../../utils/assets';
import { useTracker } from '../../utils/contexts/tracker-context';
import clsx from 'clsx';

function Product(props) {
    const { index, course, product, isTastingMenu } = props;

    const { restaurant } = useRestaurant();
    const { type } = useParams();
    const { trackDataFn } = useTracker();

    const [showDishModal, setShowDishModal] = useState(false);

    async function onDetailsOpenHandler() {
        await trackDataFn(restaurant.id, restaurant.token, "dishview", { "type": type, "dish": product.id, "name": product.name, "price": product.price }, restaurant.has_orders);
        // try {
        //     await window.fbq("track", "ViewContent");
        // } catch (e) {
        //     console.log({ pixelError: e })
        // }
        // finally {
        setShowDishModal(true);
        // }
    };

    return (
        <div className="product p-2">
            {product.special && <div className="text-sm px-3 bg-yellow-500 rounded-t-lg text-center w-1/2">PIATTO CONSIGLIATO</div>}

            <div className={clsx("product-content flex flex-col rounded-lg shadow", { "bg-grey-100": formats.isEven(index), "bg-white": !formats.isEven(index) })}>
                <div className="flex">
                    {product.image_path != "" ? <img className="object-cover w-32 h-32 m-2 border border-grey-300 rounded-xl" onClick={onDetailsOpenHandler} src={product.image_path}></img> : null}

                    <div className="flex-1 py-2 px-4">
                        <p className="flex items-center font-semibold">
                            {formats.capitalize(product.name)}
                            {formats.shouldDisplayFilterIcon(product) ? <img className="inline-block pl-1 w-6 mr-2" src={product.icon == "images/icons/menu/ic_tick_gray.png" ? assets.assetsPath("svg/legend/triangolo_grigio.svg") : assets.assetsPath(product.icon)} /> : null}
                        </p>

                        <p className="text-sm">{product.description}</p>

                        <Details dish={product} showDishModal={showDishModal} setShowDishModal={setShowDishModal} onDetailsOpenHandler={onDetailsOpenHandler} />

                        {!isTastingMenu && formats.cleanPrice(product.price) !== "" ? <p className="mt-2">{formats.formatPrice(product.price)}</p> : null}
                    </div>
                    <div className="flex flex-col items-end py-2 pr-4 text-base">
                        <PreferredButton item={product} />
                    </div>
                </div>

                {product.suggested_drink != "" && <div className="suggested-drink px-3 py-1 text-sm bg-grey-400">
                    <p><span className="font-bold">Ottimo con: </span>{product.suggested_drink.name}</p>
                </div>}

                {(!isTastingMenu && formats.shouldDisplayCta(type, restaurant.orders)) ? <Cta course={course} product={product} /> : null}
            </div>
        </div>
    );
}

export default Product;
